import { useCallback, useEffect, useState } from 'react'

export type useRecaptchaParams = {
    siteKey?: string
    action?: string
}
export function useGoogleRecaptcha(params: useRecaptchaParams) {
    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (!params.siteKey) {
            setReady(true)
            return
        }

        const scriptURL = new URL('https://www.google.com/recaptcha/api.js')
        scriptURL.searchParams.set('render', params.siteKey || '')

        const script = document.createElement('script')
        script.src = scriptURL.toString()
        script.async = true
        script.defer = true
        script.onload = () => {
            window.grecaptcha.ready(() => {
                setReady(true)

                const badgeContainer = document.querySelector('.grecaptcha-badge') as HTMLElement;
                if (badgeContainer) badgeContainer.style.zIndex = '9999';
            })
        }

        document.head.appendChild(script)

        return () => {
            setReady(false)
            document.head.removeChild(script)

            const badgeContainer = document.querySelector('.grecaptcha-badge') as HTMLElement;
            if (badgeContainer) badgeContainer.style.zIndex = '-1';
        }
    }, [params.siteKey])

    const execute = useCallback(async () => {
        if (!params.siteKey) return;

        if (!ready) throw new Error('recaptcha was not loaded properly');

        return await window.grecaptcha.execute(params.siteKey || '', {
            action: params.action || '',
        })
    }, [params.siteKey, params.action, ready])

    return { ready, execute }
}